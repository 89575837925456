import firebase from "firebase/app"
import {setToken} from "./api_helper.js"
// Add the Firebase products that you want to use
import "firebase/auth"
import "firebase/firestore"

class FirebaseAuthBackend {
  constructor(firebaseConfig) {
    if (firebaseConfig) {
      // Initialize Firebase
      firebase.initializeApp(firebaseConfig)
      const collection = firebase.firestore().collection("users")

      firebase.auth().onAuthStateChanged(user => {
        if (user) {
          var u = {};
          u.email = user.email;
          u.uid = user.uid;
          u.displayName = user.displayName;
          localStorage.setItem("authUser", JSON.stringify(u))

          user.getIdToken()
              .then(function(idToken) {
                const accessToken =  "Bearer " + idToken;
                localStorage.setItem("authToken", accessToken)
                setToken(accessToken);
                return idToken;
              });

          
        } else {
          localStorage.removeItem("authUser")
        }
      })
    }
  }


  
  loginUser = (email, password) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(
          user => {
            resolve(firebase.auth().currentUser)
          },
          error => {
            //reject(this._handleError(error))
            reject(this._handleError({ message: "Giriş yapılamadı. Geçersiz e-posta adresi ya da şifre"}))
          }
        )
    })
  }

  
  forgetPassword = email => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .sendPasswordResetEmail(email, {
          url:
            window.location.protocol + "//" + window.location.host + "/login",
        })
        .then(() => {
          resolve(true)
        })
        .catch(error => {
          reject(this._handleError(error))
        })
    })
  }

  
  logout = () => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signOut()
        .then(() => {
          resolve(true)
        })
        .catch(error => {
          reject(this._handleError(error))
        })
    })
  }



  getAuthenticatedUser = () => {
    if (!localStorage.getItem("authUser")) return null
    return JSON.parse(localStorage.getItem("authUser"))
  }

  /**
   * Handle the error
   * @param {*} error
   */
  _handleError(error) {
    // var errorCode = error.code;
    var errorMessage = error.message
    return errorMessage
  }
}

let _fireBaseBackend = null

/**
 * Initilize the backend
 * @param {*} config
 */
const initFirebaseBackend = config => {
  if (!_fireBaseBackend) {
    _fireBaseBackend = new FirebaseAuthBackend(config)
  }
  return _fireBaseBackend
}

/**
 * Returns the firebase backend
 */
const getFirebaseBackend = () => {
  return _fireBaseBackend
}

export { initFirebaseBackend, getFirebaseBackend }
