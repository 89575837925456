import { combineReducers } from "redux"

import Layout from "./layout/reducer"

import Login from "./auth/login/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"

import Dashboard from "pages/Dashboard/store/reducer"


const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  ForgetPassword,
  Dashboard
})

export default rootReducer
