import { GET_DATA, GET_DATA_SUCCESS, GET_DATA_FAIL } from "./actionTypes"

const INIT_STATE = {
  data: {},
  error: {},
}

const Dashboard = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DATA:
      return {
        ...state,
        loading: true,
      }
    case GET_DATA_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      }

    case GET_DATA_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default Dashboard
