import React, { Component } from "react"
import ReactEcharts from "echarts-for-react"
 
class ChartMonthly extends Component {
  render() {
    const { data } = this.props;

    return (
      <React.Fragment>
        <ReactEcharts style={{ height: "350px" }} option={data} />
      </React.Fragment>
    )
  }
}
export default ChartMonthly
