import { call, put, takeEvery } from "redux-saga/effects"

import { GET_DATA } from "./actionTypes"
import { getDataSuccess, getDataFail } from "./actions"

import { get, post } from "helpers/api_helper"

function* onGetData(data) {
  try {
    console.log(data.payload)
    const response = yield call(()=>post("stats", data.payload))
    yield put(getDataSuccess(response))
  } catch (error) {
    yield put(getDataFail(error))
  }
}

function* dashboardSaga() {
  yield takeEvery(GET_DATA, onGetData)
}

export default dashboardSaga
