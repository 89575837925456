import React, { useEffect, useState } from "react"
import PropTypes, { bool } from "prop-types"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import {Container, Row, Col, Spinner, Card, CardBody, FormGroup, Input, InputGroup, Label } from "reactstrap"
import { isEmpty, map, size } from "lodash"
import moment from "moment"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { Flatpickrtr } from "flatpickr/dist/l10n/tr.js"
import { MDBDataTable } from "mdbreact"
import "./datatables.scss"


import ChartMonthly from "./components/ChartMonthly.js"



import { getData } from "./store/actions"
import { MetaTags } from "react-meta-tags"



const Dashboard = props => {
  const {customerName, data, chart, loading, onGetData } = props
  const [startDate, setStartDate] = useState(new Date(moment().subtract(1, "year").startOf("month")))
  const [endDate, setEndDate] = useState(new Date(moment().endOf('month')))
  
  

  
  useEffect(() => {
    onGetData({
      startDate,
      endDate
    })
  }, [onGetData, endDate])

  const onDateChange = (selectedDates, dateStr, instance) => {
    console.log(selectedDates);
    setStartDate(selectedDates[0]);
    setEndDate(moment(selectedDates[1]).add(1, "day").subtract(1, "second"));
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Ana Sayfa | LEGAL ONLINE ADMIN</title>
      </MetaTags>

      {loading?(
        <div className="preloader" style={{display:"block", opacity: 0.9}}>
        <div className="preloader-status" style={{display:"block"}}>
          <div className="spinner-chase">
            <div className="chase-dot"/>
            <div className="chase-dot"/>
            <div className="chase-dot"/>
            <div className="chase-dot"/>
            <div className="chase-dot"/>
            <div className="chase-dot"/>
          </div>
        </div>
      </div>
      ):null}


      
      <div className="page-content">
        <Container fluid>

        <Row>
          <Col xs="12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-0 font-size-18">{customerName}</h4>
              <div className="page-title-right">
                <FormGroup className="mb-4">
                  <InputGroup>
                    <Flatpickr
                      className="form-control d-block"
                      onClose={onDateChange}
                      options={{
                        mode: "range",
                        dateFormat: "d.m.Y",
                        locale: "tr",
                        defaultDate: [startDate, endDate]
                      }}
                    />
                    <div className="input-group-append">
                              <button
                                type="button"
                                className="btn btn-outline-secondary docs-datepicker-trigger"
                                disabled
                                
                              >
                                <i
                                  className="fa fa-calendar"
                                  aria-hidden="true"
                                />
                              </button>
                            </div>
                  </InputGroup>
                </FormGroup>
              </div>
            </div>
          </Col>
        </Row>
          <Row>
            <Col lg="12" xl="6">
                  {chart.monthlyChart ? (
                <Card>
                  <CardBody>
                  <h5 className="font-size-15 mb-4">Aylık Erişilen Belge Sayısı</h5>
                    <ChartMonthly data={chart.monthlyChart} />
                </CardBody>
                </Card>
                  ) : null}
            </Col>
            <Col lg="12" xl="6">
              {data.monthlyData ? (
                <Card>
                  <MDBDataTable responsive bordered hover  scrollY maxHeight='386px'  searching={false} info={false}  paging={false} data={data.monthlyData} />
                </Card>
              ) : null}
              
            </Col>
          </Row>


          <Row>

          <Col lg="12" xl={!data.ipData ? "12" : "6"}>
            {data.userData ? (
                <Card>
                  <MDBDataTable small responsive bordered hover  scrollY maxHeight='386px'  searching={false} info={false}  paging={false} data={data.userData} />
                </Card>
              ) : null}    
            </Col>

            <Col lg="12" xl={!data.userData ? "12" : "6"}>
              {data.ipData ? (
                <Card>
                  <MDBDataTable small responsive bordered hover  scrollY maxHeight='386px'  searching={false} info={false}  paging={false} data={data.ipData} />
                </Card>
              ) : null}
              
            </Col>
          </Row>


          {data.documentList ? (
          <Row>
            <Col xs="12">
            <Card>
                  <MDBDataTable pagesAmount={10} entries={25} entriesOptions={[10, 25, 50,100]} responsive small infoLabel={["", " - ", "/", ""]} paginationLabel={["<", ">"]} sortable={false} noBottomColumns={true} autoWidth={false} displayEntries={false} bordered hover searching={false} info={true}  data={data.documentList} />
                </Card>
            </Col>
          </Row>
          ) : null}
        </Container>
      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  loading: bool,
  data: PropTypes.any,
  chart: PropTypes.any,
  onGetData: PropTypes.func,
}

const mapStateToProps = state =>{ 
  const { data, chart, error, loading, success } = state.Dashboard
  
  return ({
    loading,
    customerName: data.customerName,
    data: {
      monthlyData: data.monthlyData ? data.monthlyData : null,
      ipData: data.ipData ? data.ipData : null,
      userData: data.userData ? data.userData : null,
      documentList: data.documentList ? data.documentList : null,
    },
    chart: {
      monthlyChart: data.monthlyChart ? {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999",
            },
          },
        },
        color: ["#7c262c", "#005720", "#38a4f8"],
        legend:  data.monthlyChart.legend,
        xAxis: data.monthlyChart.xAxis,
        yAxis: data.monthlyChart.yAxis,
        series: data.monthlyChart.series,
        
      } : null
    }
  })
}

const mapDispatchToProps = dispatch => ({
  onGetData: filter => dispatch(getData(filter)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)

//export default Dashboard;