import { all, fork } from "redux-saga/effects"

//public
import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import LayoutSaga from "./layout/saga"
import dashboardSaga from "pages/Dashboard/store/saga"

export default function* rootSaga() {
  yield all([
    //public
    fork(AuthSaga),
    ForgetSaga(),
    LayoutSaga(),
    fork(dashboardSaga)
  ])
}
